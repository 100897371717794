import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import TableContainer from "../../../components/Common/TableContainer";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
  Button,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Date, NameSurname } from "./contactlistCol";


//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";
import axios from 'axios';
import "jspdf-autotable";

import * as XLSX from 'xlsx';
import logo from '../../../assets/images/logo-dark.png'; // Logo dosyanızın doğru yolunu yazın

import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

import {
  getUsers as onGetUsers,
  addUser as onAddUser,
  updateUser as onUpdateUser,
  deleteUser as onDeleteUser,
} from "store/contacts/actions";
import { isEmpty } from "lodash";


//redux
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

const ContactsList = props => {
  const tableRef = useRef(null);

  //meta title
  document.title = "Kullanıcı | EfsoKoç Admin PANELI";

  const dispatch = useDispatch();
  const [contact, setContact] = useState();
  const [contactEdit, setEditContact] = useState();

  const [getFileSubMedia, setFileSubMedia] = useState([]);

  const [getSubMediaID, setSubMediaID] = useState();

  const [getFileSubMediaAdd, setFileSubMediaAdd] = useState([]);

  // validation

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      username: (contactEdit && contactEdit.Users_NameSurname) || "",
      tel: (contactEdit && contactEdit.Users_TelephoneNumber) || "",
      password: (contactEdit && contactEdit.Users_LoginPasswordCode) || "",
      email: (contactEdit && contactEdit.MailAdress) || "",
      

    },
    validationSchema: Yup.object({
 
      username: Yup.string(3).required("Kullanıcı adı Giriniz"),

    }),

    onSubmit: values => {
      if (isEdit) {
        var localDate = moment().local();

        const updateUser = {
          ID: contactEdit.ID,
          Users_NameSurname: values.username,
          Users_TelephoneNumber: values.tel,
          Users_LoginPasswordCode: values.password,
        //  UsersPhoto:getFileName,
          Users_Type_ID: getCategoryID,
          MailAdress:values.email,
          Users_CreatedDateTime:localDate
        };
        dispatch(onUpdateUser(updateUser));
        setIsEdit(false);
        validation.resetForm();
      } else {
        console.log("lknskldşf",getCategoryID)
        var localDate = moment().local();

        const newUser = {
          Users_NameSurname: values["username"],
          Users_TelephoneNumber:values["tel"],
          MailAdress:values["email"],
          Users_LoginPasswordCode: values["password"],
        //  UsersPhoto:getFileName,
          Users_Type_ID: getCategoryID,
          Users_CreatedDateTime:localDate
        };
        // save new user
        dispatch(onAddUser(newUser));
        validation.resetForm();
      }
      toggle();
    },
  });

  const { users } = useSelector(state => ({
    users: state.contacts.users,
  }));

  const [userList, setUserList] = useState([]);
  const [modal, setModal] = useState(false);
  const [modalMedia, setModalMedia] = useState(false);

  const [isEdit, setIsEdit] = useState(false);

  const [getCategoryID, setCategoryID] = useState(null);


  const [getCategory, setCategory] = useState([]);

  const onClickDelete = users => {
    setContact(users);
    setDeleteModal(true);
  };

  const columns = useMemo(
    () => [
      {
        Header: "#",
        Cell: () => {
          return <input type="checkbox" className="form-check-input" />;
        },
      },
     
      {
        Header: "ID",
        accessor: "ID",
        filterable: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },

      {
        Header: "Adı",
        accessor: "Users_NameSurname",
        filterable: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },

      {
        Header: "Kullanıcı Tipi",
        accessor: "Users_Type.Users_Type_Text",
        filterable: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },

      {
        Header: "Mail Adresi",
        accessor: "MailAdress",
        filterable: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },

      
      {
        Header: "Kayıt Tarihi",
        accessor: "Users_CreatedDateTime",
        filterable: true,
        Cell: cellProps => {
          return <Date {...cellProps} />;
        },
      },

      
      {
        Header: "Telefon Numarası",
        accessor: "Users_TelephoneNumber",
        filterable: true,
        Cell: cellProps => {
          return <NameSurname {...cellProps} />;
        },
      },




      {
        Header: "İşlemler",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">

         
{cellProps.row.original.Plan.length > 0 ?
<Link
                to="#"
                className="text-success"
                onClick={() => {
                  const userData = cellProps.row.original;
                  handleUserClickPdfAll(userData);
                }}
              >
                <i className="mdi mdi-check-all font-size-18" id="pdftooltipall" />
                <UncontrolledTooltip placement="top" target="pdftooltipall">
                 Tüm PDF
                </UncontrolledTooltip>
              </Link> : null }

{cellProps.row.original.Plan.length > 0 ?
<Link
                to="#"
                className="text-success"
                onClick={() => {
                  const userData = cellProps.row.original;
                  handleUserClickPdf(userData);
                }}
              >
                <i className="mdi mdi-export font-size-18" id="pdftooltip" />
                <UncontrolledTooltip placement="top" target="pdftooltip">
                 Haftalık PDF
                </UncontrolledTooltip>
              </Link> : null }
             

              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const userData = cellProps.row.original;
                  handleUserClick(userData);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Düzenle
                </UncontrolledTooltip>
              </Link>
             

              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const userData = cellProps.row.original;
                  onClickDelete(userData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Sil
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );

  const _getAuthData = async () => {

    await axios.get(`https://sihaadmin.stechomeyazilim.info:8182/getUsersTypeEfso/select/`)
      .then((res) => {
        setCategory(res.data)
      })
  }

  useEffect(() => {
    _getAuthData()
    if (users && !users.length) {
      dispatch(onGetUsers());
      setIsEdit(false);
    }
  }, [dispatch, users]);

  useEffect(() => {
    setContact(users);
    // setIsEdit(false);
  }, [users]);

  useEffect(() => {
    if (!isEmpty(users) && !!isEdit) {
      setContact(users);
      //setIsEdit(false);
    }
  }, [users]);

  const toggle = () => {
    setModal(!modal);
  };

  const toggleMedia = () => {
    setModalMedia(!modalMedia);
  };



  
  const toBase64 = async (url) => {
    const response = await fetch(url);
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    const blob = await response.blob();
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
    });
};

const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleString(); // Yerel tarih ve saat formatı
};


  const handleUserClickPdf = async(arg) => {
    try {

      const userData = arg;


      const base64Logo = await toBase64(logo);
            const workbook = new ExcelJS.Workbook();
            const userSheet = workbook.addWorksheet('Kullanıcı Bilgileri');
            const planSheet = workbook.addWorksheet('Ders Planları');

            // Kullanıcı bilgilerini ekleme
            userSheet.addRow(['Kullanıcı Adı', userData.Users_NameSurname]);
            userSheet.addRow(['Telefon Numarası', userData.Users_TelephoneNumber]);
            userSheet.addRow(['Email', userData.MailAdress]);

            // Logoyu ekleme
            const imageId = workbook.addImage({
                base64: base64Logo,
                extension: 'png',
            });

            userSheet.addImage(imageId, {
                tl: { col: 1, row: 6 },
                ext: { width: 350, height: 200 },
            });

            // Bir haftalık planları filtreleme
            const oneWeekFromNow = new window.Date();
            oneWeekFromNow.setDate(oneWeekFromNow.getDate() + 7);

            const filteredPlans = userData.Plan.filter(plan => {
                const planStartDate = new window.Date(plan.StartDateTime);
                return !isNaN(planStartDate) && planStartDate >= new window.Date() && planStartDate <= oneWeekFromNow;
            });

            // Ders planlarını ekleme
            planSheet.addRow([
                'Başlangıç Zamanı',
                'Bitiş Zamanı',
                'Soru Sayısı',
                'Plan Kategorisi',
                'Plan Aktivitesi',
                'Kitap Başlığı',
                "AltBaşlık",
                "Konu",
                "Ders"

            ]);

            if (filteredPlans.length > 0) {
                filteredPlans.forEach(plan => {
                    planSheet.addRow([
                        moment(plan.StartDateTime).format('DD/MM/YYYY'), 
                        moment(plan.FinishDateTime).format('DD/MM/YYYY'), 
                        plan.NumberOfQuestions || 'Belirtilmemiş',
                        plan.Plan_Category,
                        plan.Plan_Activity || 'Yok',
                        plan.Plan_BookTitle || 'Belirtilmemiş',
                        plan.Book_SubSubject.Book_SubSubject_Title,
                        plan.Book_SubSubject.Book_Subject.Book_Subject_Title,
                        plan.Book_SubSubject.Book_Subject.Lesson.Lesson_Title
                    ]);
                });
            } else {
                planSheet.addRow(['Ders Planı Bulunamadı']);
            }

            // Excel dosyasını oluştur ve indir
            const buffer = await workbook.xlsx.writeBuffer();
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            saveAs(blob, userData.Users_NameSurname + ' KullaniciBilgileri_DersPlanlari.xlsx');
  } catch (error) {
      console.error('Error exporting to Excel:', error);
  }
  };

  const handleUserClickPdfAll = async(arg) => {
    try {

      const userData = arg;


      const base64Logo = await toBase64(logo);
            const workbook = new ExcelJS.Workbook();
            const userSheet = workbook.addWorksheet('Kullanıcı Bilgileri');
            const planSheet = workbook.addWorksheet('Ders Planları');

            // Kullanıcı bilgilerini ekleme
            userSheet.addRow(['Kullanıcı Adı', userData.Users_NameSurname]);
            userSheet.addRow(['Telefon Numarası', userData.Users_TelephoneNumber]);
            userSheet.addRow(['Email', userData.MailAdress]);

            // Logoyu ekleme
            const imageId = workbook.addImage({
                base64: base64Logo,
                extension: 'png',
            });

            userSheet.addImage(imageId, {
                tl: { col: 1, row: 6 },
                ext: { width: 350, height: 200 },
            });


            // Ders planlarını ekleme
            planSheet.addRow([
                'Başlangıç Zamanı',
                'Bitiş Zamanı',
                'Soru Sayısı',
                'Plan Kategorisi',
                'Plan Aktivitesi',
                'Kitap Başlığı',
                "AltBaşlık",
                "Konu",
                "Ders"

            ]);

            if (userData.Plan.length > 0) {
              userData.Plan.forEach(plan => {
                    planSheet.addRow([
                        moment(plan.StartDateTime).format('DD/MM/YYYY'), 
                        moment(plan.FinishDateTime).format('DD/MM/YYYY'), 
                        plan.NumberOfQuestions || 'Belirtilmemiş',
                        plan.Plan_Category,
                        plan.Plan_Activity || 'Yok',
                        plan.Plan_BookTitle || 'Belirtilmemiş',
                        plan.Book_SubSubject.Book_SubSubject_Title,
                        plan.Book_SubSubject.Book_Subject.Book_Subject_Title,
                        plan.Book_SubSubject.Book_Subject.Lesson.Lesson_Title
                    ]);
                });
            } else {
                planSheet.addRow(['Ders Planı Bulunamadı']);
            }

            // Excel dosyasını oluştur ve indir
            const buffer = await workbook.xlsx.writeBuffer();
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            saveAs(blob, 'KullaniciBilgileri_DersPlanlari.xlsx');
  } catch (error) {
      console.error('Error exporting to Excel:', error);
  }
  };
  const handleUserClick = (arg) => {
    const user = arg;

    setEditContact(user);

    setCategoryID(user.Users_Type_ID)


    setIsEdit(true);


    toggle();
  };


  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  //delete customer
  const [deleteModal, setDeleteModal] = useState(false);



  const handleDeleteUser = () => {
    if (contact && contact.ID) {
      dispatch(onDeleteUser(contact.ID));
    }
    onPaginationPageChange(1);
    setDeleteModal(false);
  };

  const handleUserClicks = () => {
    setUserList("");
    setIsEdit(false);
    toggle();
  };

  const _setChoiceCategory = arg => {
    setCategoryID(arg.target.value)
  }

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };


  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Yönetim" breadcrumbItem="Kullanıcı Listesi" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>

                  <TableContainer
                    columns={columns}
                    data={users}
                    ref={tableRef}
                    isGlobalFilter={true}
                    isAddForumList={true}
                    handleOrderClicks={handleUserClicks}
                    customPageSize={10}
                    className="custom-header-css"
                  />
                

                  <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? "Düzenle" : "Kullanıcı Ekle"}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={e => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <Row>
                          <Col xs={12}>



                            <div className="mb-3">
                              <Label className="form-label">Kullanıcı Adı</Label>
                              <Input
                                name="username"
                                label="username"
                                type="text"
                                placeholder="Kullanıcı Adını Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.username || ""}
                                invalid={
                                  validation.touched.username &&
                                    validation.errors.username
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.username &&
                                validation.errors.username ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.username}
                                </FormFeedback>
                              ) : null}
                            </div>



                            <div className="mb-3">
                              <Label className="form-label">Email Adresi</Label>
                              <Input
                                name="email"
                                label="email"
                                type="text"
                                placeholder="Email Adresi Giriniz"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.email || ""}
                                invalid={
                                  validation.touched.email &&
                                    validation.errors.email
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.email &&
                                validation.errors.email ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.email}
                                </FormFeedback>
                              ) : null}
                            </div>

                          
                            <div className="mb-3">
                              <Label className="form-label">Kullanıcı Tipi Seçiniz</Label>
                              <Input
                                name="paymentStatus"
                                type="select"
                                className="form-select"
                                defaultValue={getCategoryID}
                                onChange={(text) => _setChoiceCategory(text)}
                                value={
                                  getCategoryID
                                }>
                               <option value="">Lütfen Seçim Yapınız</option>


                                {getCategory.map((item, index) => (
                                  <option value={item.ID} key={item.ID}>{item.Users_Type_Text}</option>
                                ))}

                              </Input>
                              {validation.touched.wsText && validation.errors.wsText ? (
                                <FormFeedback type="invalid">{validation.errors.wsText}</FormFeedback>
                              ) : null}
                            </div>

                            <div className="mb-3">
                              <Label className="form-label">Kullanıcı Şifre</Label>
                              <div style={{ display: "flex", alignItems: "center" }}>

                              <Input
                                name="password"
                                label="password"
                                type="text"
                                placeholder="Kullanıcı Şifresi"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.password || ""}
                                invalid={
                                  validation.touched.password &&
                                    validation.errors.password
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.password &&
                                validation.errors.password ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.password}
                                </FormFeedback>
                              ) : null}
                                 <Button
          type="button"
          onClick={togglePasswordVisibility}
          style={{ marginLeft: "10px" }}
        >
          {showPassword ? "Gizle" : "Göster"}
        </Button>
        </div>

                            </div>


                            <div className="mb-3">
                              <Label className="form-label">Kullanıcı Telefon</Label>
                              <Input
                                name="tel"
                                label="tel"
                                type="text"
                                placeholder="Kullanıcı Telefon"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.tel || ""}
                                invalid={
                                  validation.touched.tel &&
                                    validation.errors.tel
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.tel &&
                                validation.errors.tel ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.tel}
                                </FormFeedback>
                              ) : null}
                            </div>
                           

                       
                          </Col>
                        </Row>
                        <Row>



                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-success save-user"
                              >
                                Kaydet
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ContactsList);
