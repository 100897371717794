import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_ADVERT, DELETE_ADVERT,UPDATE_ADVERT,ADD_ADVERT, GET_AVAILABLE, DELETE_AVAILABLE,UPDATE_AVAILABLE,ADD_AVAILABLE,GET_OFFER, DELETE_OFFER,UPDATE_OFFER,ADD_OFFER, GET_BLOGS, GET_RESERVATION, DELETE_RESERVATION,UPDATE_RESERVATION,ADD_RESERVATION,GET_SLİDERS,GET_SSS,DELETE_SSS,ADD_SSS,UPDATE_SSS, DELETE_DYNAMICFORM, DELETE_PRODUCTCATEGORY, DELETE_DYNAMICFORMEXTRA, DELETE_COURSESCHEDULES,
  DELETE_GALLERY, DELETE_NEWS, GET_DYNAMICFORM_EXTRA_EXTRA, GET_DYNAMICFORM_EXTRA, UPDATE_DYNAMICFORM, GET_CLASSES, GET_CLASSATTENDANCE,
  UPDATE_LESSONS_TIME, UPDATE_CONTACT, UPDATE_ABOUT, GET_TEACHER_WORKSHOP, GET_SUBPROJECT, UPDATE_REFERANCES, ADD_REFERANCES, GET_REFERANCES, ADD_WORKSHOP2, ADD_APPSETTING,
  ADD_WORKSHOPSUBDOMAIN, UPDATE_GALLERY, GET_GALLERY, UPDATE_WORKSHOP2, UPDATE_MEGA, UPDATE_AUTH, GET_AUTHORITY, GET_CONTACT,GET_ABOUT,
  UPDATE_WORKSHOPSESSION, UPDATE_SUBDOMAIN, ADD_COURSE, ADD_MEGA, GET_MEGA, GET_NEWS, GET_PROJECTSNEW, GET_SLIDER, GET_DYNAMICFORM, GET_EVENT,
  GET_TeacherMega, GET_WorkshopSessionMega, GET_CourseSchedulesMega, GET_POPUP, GET_USERS, GET_USER_PROFILE,
  ADD_NEW_USER, ADD_USER,DELETE_USER, UPDATE_USER, GET_WORKSHOP, ADD_POPUP, ADD_PRODUCTCATEGORY, ADD_SLIDER,
  ADD_PROJECT, ADD_NEWS, UPDATE_NEWS, UPDATE_TEACHER, UPDATE_COURSESCHEDULES, UPDATE_SLIDER, UPDATE_PROJECT, GET_WORKSHOP2,
  ADD_LESSONS_TIME, ADD_DYNAMIC_FORM, DELETE_MEGA, DELETE_SLIDER,DELETE_BLOG, DELETE_POPUP, DELETE_REFERANCES, ADD_GALLERY,
  ADD_GALLERYMULTIPLE, DELETE_DYNAMICFORMEXTRAEXTRA, GET_EDUCATION, GET_SUBEDUCATION, UPDATE_EDUCATION, ADD_EDUCATION,
  UPDATE_SUBEDUCATION, ADD_SUBEDUCATION, DELETE_SUBEDUCATION, GET_PRODUCTCATEGORY, GET_PRODUCT, ADD_BLOG, UPDATE_PRODUCTCATEGORY,UPDATE_BLOG,ADD_PRODUCT,UPDATE_PRODUCT,DELETE_PRODUCT, GET_PRODUCTSUBCATEGORY, GET_BRAND, UPDATE_BRAND, GET_PRODUCTSUBSUBCATEGORY, ADD_SUBSUBCATEGORY, UPDATE_SUBSUBCATEGORY, ADD_BRAND, ADD_SUBCATEGORY, UPDATE_PRODUCTSUBCATEGORY,
  UPDATE_POPUP,
  DELETE_PRODUCTSUBCATEGORY,
  DELETE_SUBSUBCATEGORY,
  GET_LIMIT,
  UPDATE_LIMIT,
  DELETE_LIMIT,
  ADD_LIMIT,
  UPDATE_PDR,
  DELETE_PDR,
  ADD_PDR,
  GET_PDR,
  ADD_NEWPOST,
  DELETE_TEACHER_SUCCESS,
  DELETE_TEACHER,
  GET_SONG,
  ADD_SONG_CATEGORY,
  GET_SONG_CATEGORY,
  UPDATE_SONG_CATEGORY,
  DELETE_SONG_CATEGORY,
  UPDATE_SONG,
  DELETE_SONG,
  ADD_SONG,
  GET_POST,
  DELETE_POST,
  UPDATE_POST,
  GET_MOTIVATION,
  UPDATE_MOTIVATION,
  ADD_MOTIVATION,
  DELETE_MOTIVATION,
  DELETE_BOOK,
  GET_BOOK,
  ADD_BOOK,
  UPDATE_BOOK,
  ADD_COUNTER,
  UPDATE_COUNTER,
  GET_COUNTER,
  DELETE_COUNTER,
  GET_SALES_POINT,
  ADD_SALES_POINT,
  UPDATE_SALES_POINT,
  DELETE_SALES_POINT,
  DELETE_COACH,
  GET_COACH,
  ADD_COACH,
  UPDATE_COACH,
  DELETE_FEEDBACKCATEGORY,
  ADD_FEEDBACKCATEGORY,
  UPDATE_FEEDBACKCATEGORY,
  GET_FEEDBACKCATEGORY,
  GET_FEEDBACK,
  DELETE_FEEDBACK,
  ADD_FEEDBACK,
  UPDATE_FEEDBACK,
  DELETE_PURCH,
  ADD_PURCH,
  UPDATE_PURCH,
  GET_PURCH,
  GET_SCHOOL,
  ADD_SCHOOL,
  UPDATE_SCHOOL,
  DELETE_SCHOOL,
  GET_TEACHERSCHOOL,
  GET_STUDENT,
  GET_USERSWEB
} from "./actionTypes"

import {
  addNewPostSuccess,
  updateOfferSuccess,
  getOfferSuccess,
  deleteOfferSuccess,
  getUsersFail,
  getUserProfileSuccess,
  getUserProfileFail,
  addUserFail,
  addUserSuccess,
  addSliderSuccess,
  addAdvertSuccess,
  updateAdvertSuccess,
  getAdvertSuccess,
  deleteAdvertSuccess,
  addSSSSuccess,
  updateSSSSuccess,
  getSSSSuccess,
  deleteSSSSuccess,
  updateUserSuccess,
  updateUserFail,
  deleteUserSuccess,
  deleteUserFail,
  getPopupSuccess,
  getWorkshopSuccess,
  getWorkshopSessionSuccess,
  getApplicationSuccess,
  getSliderSuccess,
  getProjectSuccess,
  getNewsSuccess,
  getMegaSuccess,
  getAboutSuccess,
  getTeacherSuccess,
  getDynamicSuccess,
  getAuthSuccess,
  getAuthUpdateSuccess,
  updateSubdomainSuccess,
  updateSuccessCourseSchedules,
  updateWorkShopSessionSuccess,
  updateTeacherSuccess,
  updateSliderSuccess,
  updateSuccessProject,
  updateContactSuccess,
  updateAboutSuccess,
  updateNewsSuccess,
  updateBlogSuccess,
  updateMegaSuccess,
  getWorkshop2Success,
  updateWorkShop2Success,
  getGallerySuccess,
  updateGallerySuccess,
  getReferancesSuccess,

  addSuccessReferances,
  updateSuccessReferances,
  getSubProjectSuccess,
  getTeacherWorkshopSuccess,
  addSuccessLessonsTime,
  updateLessonsTimeSuccess,
  getClassAttendanceSuccess,
  getClassesSuccess,
  updateDynamicFormSuccess,
  addDynamicFormSuccess,
  getDynamicFormExtraSuccess,
  getDynamicFormExtraExtraSuccess,

  addMegaSuccess,
  deleteMegaSuccess,
  deleteProductCategorySuccess,
  deleteNewsSuccess,
  deleteSliderSuccess,
  deleteBlogSuccess,
  deletePopupSuccess,
  deleteReferancesSuccess,
  addGallerySuccess,
  deleteGallerySuccess,
  deleteCourseSchedulesSuccess,
  deleteDynamicFormSuccess,
  deleteDynamicExtraFormSuccess,

  deleteDynamicExtraExtraFormSuccess,
  getEducationSuccess,
  getSubEducationSuccess,
  updateEducationSuccess,
  addEducationSuccess,
  updateSubEducationSuccess,
  addSubEducationSuccess,
  deleteSubEducationSuccess,
  getProductCategorySuccess,
  getProductSuccess,
  getSlidersSuccess,
  getUsersSuccess,
  getBlogsSuccess,
  addProductCategorySuccess,
  addBlogSuccess,
  addProductSuccess,
  deleteProductSuccess,
  updateProductSuccess,
  addUser,
  addReservationSuccess,
  deleteReservationSuccess,
  updateReservationSuccess,
  getReservationSuccess,
  addAvailableSuccess,
  deleteAvailableSuccess,
  updateAvailableSuccess,
  getAvailableSuccess,
  getProductSubCategorySuccess,
  getBrandSuccess,
  onUpdateBrandSuccess,
  getProductSubSubCategorySuccess,
  addProductSubSubCategorySuccess,
  updateSubSubProductCategorySuccess,
  addBrandSuccess,
  addProductSubCategorySuccess,
  updateProductSubCategorySuccess,
  updatePopupSuccess,
  addPopupSuccess,
  deleteProductSubCategorySuccess,
  deleteProductSubSubCategorySuccess,
  getLimitSuccess,
  updateLimitSuccess,
  deleteLimitSuccess,
  addLimitSuccess,
  updateProductCategorySuccess,
  updatePdrSuccess,
  deletePdrSuccess,
  addPdrSuccess,
  getPdrSuccess,
  getSongSuccess,
  getSongCategorySuccess,
  deleteSongCategorySuccess,
  addSongCategorySuccess,
  updateSongCategorySuccess,
  updateSongSuccess,
  deleteSongSuccess,
  addSongSuccess,
  getPostSuccess,
  deletePostSuccess,
  updatePostSuccess,
  getMotivationSuccess,
  deleteMotivationSuccess,
  addMotivationSuccess,
  updateMotivationSuccess,
  updateBookSuccess,
  getBookSuccess,
  addBookSuccess,
  deleteBookSuccess,
  getCounterSuccess,
  deleteCounterSuccess,
  updateCounterSuccess,
  addCounterSuccess,
  updateSalesPointSuccess,
  addSalesPointSuccess,
  deleteSalesPointSuccess,
  getSalesPointSuccess,
  getPostCoachSuccess,
  updatePostCoachSuccess,
  addNewPostCoachSuccess,
  deletePostCoachSuccess,
  deleteFeedBackCategorySuccess,
  updateFeedbackCategorySuccess,
  getFeedBackCategorySuccess,
  deleteFeedBackSuccess,
  addFeedBackSuccess,
  addFeedbackCategorySuccess,
  getFeedBackSuccess,
  updateFeedBackSuccess,
  addPurchSuccess,
  getPurchSuccess,
  updatePurchSuccess,
  deletePurchSuccess,
  getSchoolSuccess,
  addSchoolSuccess,
  updateSchoolSuccess,
  deleteSchoolSuccess,
  getTeacherSchoolSuccess,
  getStudentSuccess,
  getUsersWebSuccess,

} from "./actions"

//Include Both Helper File with needed methods
import {   
  deleteSchool,
  updateSchool,
  addSchool,
  getSchool,
  getSalesPoint,
deleteSalesPoint,
addSalesPoint,
updateSalesPoint,

  updateCounter,
addCounter,
deleteCounter,
getCounter,

  getPost,
  addSong,
  deleteSong,
  updateSong,
  getSongCategory,addSongCategory,updateSongCategory,deleteSongCategory,
  getPdr,
  addPdr,
  deletePdr,
  updatePdr,
  updateLimit,getLimit,deleteProductSubSubCategory,deleteProductSubCategory,updatePopup ,updateProductSubCategory,addProductSubCategory,addBrand,updateSubSubProductCategory,addProductSubSubCategory  ,getProductSubSubCategory,updateBrand,getBrand,getSubCategory,updateAdvert,deleteAdvert,addAdvert ,getAdvert,updateAvailable,deleteAvailable,addAvailable ,getAvailable,updateOffer,deleteOffer,addOffer ,getOffer,updateReservation,deleteReservation,addReservation ,getReservation, updateProduct,deleteProduct,addProduct ,updateProductCategory,updateAbout, getSSS,addSSS,updateSSS,deleteSSS,getBlogs, getSliders, getCategory, deleteSubEducation, deleteBlog, deleteProductCategory, addSubEducation,addBlog, updateSubEducation, addEducation, updateEducation, getEducationSubItem, getEducation, deleteDynamicFormExtraExtra, deleteDynamicForm, deleteDynamicFormExtra, deleteCourseSchedules, addMultipleGalleryForm, deleteGallery, addGallerry, addProductCategory, deleteReferances, deletePopup, deleteSlider, deleteNews, deleteMega, getDynamicFormExtraExtraMega, getDynamicFormExtraMega, addDynamicForm, updateDynamicForm, getClassesMega, getClassAttendance, updateLessonsTime, addLessonsTime, getTeacherWorkshop, getSubProject, updateReferances, addReferances, getReferances, addWorkshop2, addAppSetting, addNewSubdomain, updateGallery, getGallery, updateWorkShop2, getWorkshop2, updateMega, updateProject, updateSlider, updateAuth, getAuthority, updateContact, getContact, getAbout,updateBlog, updateTeacher, updateWorkshopSession, updateSubdomain, addCourseMega, updateCourseSchedules, updateNews, addMega, addNews, addProject, addSlider, addPopup, getMegaMega, getNews, getProjectsNew, getSlider, getDynamicFormMega, getEventMega, getTeacherMega, getWorkshopSessionMega, getPopup, getUsers, getUserProfile, addNewUser, updateUser, deleteUser, getWorkShop, getCourseSchedulesMega, getProducts1, deleteLimit, addLimit, getReservationUsers, getEducationSubItemUsers, 
  getSong,
  deletePost,
  updatePost,
  deleteMotivation,
  getMotivation,
  addMotivation,
  updateMotivation,
  getBook,
  addBook,
  deleteBook,
  updateBook,
  deletePostCoach,
  addPostCoach,
  updatePostCoach,
  getPostCoach,

  deleteFeedBackCategory,
  addFeedBackCategory,
  updateFeedbackCategory,
  getFeedBackCategory,

  getFeedBack,
  updateFeedBack,
  addFeedBack,
  deleteFeedBack,
  addPurch,
  getPurch,
  updatePurch,
  deletePurch,
  getUsersTeacher,
  getUsersStudent,
  getUsersWeb} from "../../helpers/fakebackend_helper"

  function* onDeletePurch({ payload: user }) {
    try {

      const response = yield call(deletePurch, user)
      yield put(deletePurchSuccess(user,response))
    } catch (error) {
      yield put(deleteUserFail(error))
    }
  }

  function* onUpdatePurch({ payload: user }) {
    try {
      const response = yield call(updatePurch, user)
      yield put(updatePurchSuccess(user,response))
    } catch (error) {
      yield put(updateUserFail(error))
    }
  }

  function* onGetPurch(item) {
    try {
      const response = yield call(getPurch, item.users)
      yield put(getPurchSuccess(response))
    } catch (error) {
      yield put(getUsersFail(error))
    }
  }
  
  function* onAddPurch({ payload: user }) {
    try {
      const response = yield call(addPurch, user)
  
      yield put(addPurchSuccess(response.value[0]))
    } catch (error) {
  
      yield put(addUserFail(error))
    }
  }



  

  function* onDeleteFeedBackCategory({ payload: user }) {
    try {

      const response = yield call(deleteFeedBackCategory, user)
      yield put(deleteFeedBackCategorySuccess(user,response))
    } catch (error) {
      yield put(deleteUserFail(error))
    }
  }

  function* onUpdateFeedbackCategory({ payload: user }) {
    try {
      const response = yield call(updateFeedbackCategory, user)
      yield put(updateFeedbackCategorySuccess(user,response))
    } catch (error) {
      yield put(updateUserFail(error))
    }
  }


  function* onGetFeedBackCategory(item) {
    try {
      const response = yield call(getFeedBackCategory, item.users)
      yield put(getFeedBackCategorySuccess(response))
    } catch (error) {
      yield put(getUsersFail(error))
    }
  }

  function* onDeleteFeedBack({ payload: user }) {
    try {
      const response = yield call(deleteFeedBack, user)
      yield put(deleteFeedBackSuccess(user))
    } catch (error) {
      yield put(deleteUserFail(error))
    }
  }
  function* onAddFeedBack({ payload: user }) {
    try {
      const response = yield call(addFeedBack, user)
  
      yield put(addFeedBackSuccess(response.value[0]))
    } catch (error) {
  
      yield put(addUserFail(error))
    }
  }

  function* onAddFeedbackCategory({ payload: user }) {
    try {
      const response = yield call(addFeedBackCategory, user)
  
      yield put(addFeedbackCategorySuccess(response.value[0]))
    } catch (error) {
  
      yield put(addUserFail(error))
    }
  }
  function* onGetFeedBack(item) {
    try {
      const response = yield call(getFeedBack, item.users)
      yield put(getFeedBackSuccess(response))
    } catch (error) {
      yield put(getUsersFail(error))
    }
  }
  function* onUpdateFeedBack({ payload: user }) {
    try {
      const response = yield call(updateFeedBack, user)
      yield put(updateFeedBackSuccess(response.value[0]))
    } catch (error) {
      yield put(updateUserFail(error))
    }
  }


function* onDeleteAdvert({ payload: user }) {
  try {
    const response = yield call(deleteAdvert, user)
    yield put(deleteAdvertSuccess(response,user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}

export const deleteTeacher= user => ({
  type: DELETE_TEACHER,
  payload: user,
})

export const deleteTeacherSuccess = user => ({
  type: DELETE_TEACHER_SUCCESS,
  payload: user,
})

function* onDeleteTeacher({ payload: user }) {
  try {
    const response = yield call(deleteTeacher, user)
    yield put(deleteTeacherSuccess(user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}
function* onGetSong(item) {
  try {
    const response = yield call(getSong, item.users)
    yield put(getSongSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}




function* onAddAdvert({ payload: user }) {
  try {
    const response = yield call(addAdvert, user)

    yield put(addAdvertSuccess(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}
function* onGetAdvert(item) {
  try {
    const response = yield call(getAdvert, item.users)
    yield put(getAdvertSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}


function* onUpdatePopup({ payload: user }) {
  try {
    const response = yield call(updatePopup, user)
    yield put(updatePopupSuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}

function* onUpdateProductSubCategory({ payload: user }) {
  try {
    const response = yield call(updateProductSubCategory, user)
    yield put(updateProductSubCategorySuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}

function* onUpdateAdvert({ payload: user }) {
  try {
    const response = yield call(updateAdvert, user)
    yield put(updateAdvertSuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}

function* onUpdateSubSubProductCategory({ payload: user }) {
  try {
    const response = yield call(updateSubSubProductCategory, user)
    yield put(updateSubSubProductCategorySuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}


function* onDeleteProductSubSubCategory({ payload: user }) {
  try {
    const response = yield call(deleteProductSubSubCategory, user)
    yield put(deleteProductSubSubCategorySuccess(response,user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}

function* onDeleteProductSubCategory({ payload: user }) {
  try {
    const response = yield call(deleteProductSubCategory, user)
    yield put(deleteProductSubCategorySuccess(response,user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}

function* onDeleteSubEducation({ payload: user }) {
  try {
    const response = yield call(deleteSubEducation, user)
    yield put(deleteSubEducationSuccess(user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}

function* onDeleteAvailable({ payload: user }) {
  try {
    const response = yield call(deleteAvailable, user)
    yield put(deleteAvailableSuccess(user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}
function* onAddAvailable({ payload: user }) {
  try {
    const response = yield call(addAvailable, user)

    yield put(addAvailableSuccess(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}
function* onGetAvailable(item) {
  try {
    const response = yield call(getAvailable, item.users)
    yield put(getAvailableSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}
function* onUpdateAvailable({ payload: user }) {
  try {
    const response = yield call(updateAvailable, user)
    yield put(updateAvailableSuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}







function* onDeleteOffer({ payload: user }) {
  try {
    const response = yield call(deleteOffer, user)
    yield put(deleteOfferSuccess(user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}
function* onAddOffer({ payload: user }) {
  try {
    const response = yield call(addOffer, user)

    yield put(addReservationSuccess(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}
function* onGetOffer(item) {
  try {
    const response = yield call(getOffer, item.users)
    yield put(getOfferSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}
function* onUpdateOffer({ payload: user }) {
  try {
    const response = yield call(updateOffer, user)
    yield put(updateOfferSuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}





function* onDeleteReservation({ payload: user }) {
  try {
    const response = yield call(deleteReservation, user)
    console.log("response122",response)
    yield put(deleteReservationSuccess(response,user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}
function* onAddReservation({ payload: user }) {
  try {
    const response = yield call(addReservation, user)

    yield put(addReservationSuccess(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}
function* onGetReservation(item) {
  try {
   
      const response = yield call(getReservation, null)
      console.log("şlmsd2",response)

      yield put(getReservationSuccess(response))
   
 
  } catch (error) {
    console.log("şlsdşlf",error)
    yield put(getUsersFail(error))
  }
}

function* onGetBrand(item) {
  try {
    const response = yield call(getBrand, item.users)
    yield put(getBrandSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* onUpdateReservation({ payload: user }) {
  try {
    const response = yield call(updateReservation, user)
    yield put(updateReservationSuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}


function* onUpdateBrand({ payload: user }) {
  try {
    const response = yield call(updateBrand, user)
    yield put(onUpdateBrandSuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}

function* onGetProductSubSubCategory(item) {
  try {
    const response = yield call(getProductSubSubCategory, item.users)
    yield put(getProductSubSubCategorySuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}


function* onAddProductSubSubCategory({ payload: user }) {

  try {
    const response = yield call(addProductSubSubCategory, user)

    yield put(addProductSubSubCategorySuccess(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}



function* onDeleteProduct({ payload: user }) {
  try {
    const response = yield call(deleteProduct, user)
    yield put(deleteProductSuccess(user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}

function* onAddProduct({ payload: user }) {

  try {
    const response = yield call(addProduct, user)

    yield put(addProductSuccess(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}


function* onUpdateProduct({ payload: user }) {
  try {
    const response = yield call(updateProduct, user)
    yield put(updateProductSuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}


function* onDeleteSss({ payload: user }) {
  try {
    const response = yield call(deleteSSS, user)
    yield put(deleteSSSSuccess(user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}

function* onAddSSS({ payload: user }) {

  try {
    const response = yield call(addSSS, user)

    yield put(addSSSSuccess(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}

function* onUpdateSSS({ payload: user }) {
  try {
    const response = yield call(updateSSS, user)
    yield put(updateSSSSuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}

function* onGetSSS(item) {
  try {
    const response = yield call(getSSS, item.users)
    yield put(getSSSSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}




function* onDeleteBlog({ payload: user }) {
  try {
    const response = yield call(deleteBlog, user)
    yield put(deleteBlogSuccess(user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}

function* onAddSubEducation({ payload: user }) {

  try {
    const response = yield call(addSubEducation, user)

    yield put(addSubEducationSuccess(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}


function* onUpdateSubEducation({ payload: user }) {
  try {
    const response = yield call(updateSubEducation, user)
    yield put(updateSubEducationSuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}

function* onUpdateEducation({ payload: user }) {
  try {
    const response = yield call(updateEducation, user)
    yield put(updateEducationSuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}


function* onUpdateContact({ payload: user }) {
  try {
    const response = yield call(updateContact, user)
    yield put(updateContactSuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}

function* onUpdateAbout({ payload: user }) {
  try {
    const response = yield call(updateAbout, user)
    yield put(updateAboutSuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}

function* onUpdateDynamicForm({ payload: user }) {
  try {
    const response = yield call(updateDynamicForm, user)
    yield put(updateDynamicFormSuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}

function* onGetEducationSubItem(item) {
  try {
   
    const response = yield call(getEducationSubItem, null)
    yield put(getSubEducationSuccess(response))
  
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* onGetEducation(item) {
  try {
    const response = yield call(getEducation, item.users)
    yield put(getEducationSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* ongetProductCategory(item) {
  try {
    const response = yield call(getCategory, item.users)
    console.log("response", response)
    yield put(getProductCategorySuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}


function* onGetProductSubCategory(item) {
  try {
    const response = yield call(getSubCategory, item.users)
    console.log("response", response)
    yield put(getProductSubCategorySuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* onUpdateLimit({ payload: user }) {
  try {
    const response = yield call(updateLimit, user)
    yield put(updateLimitSuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}

function* onAddLimit({ payload: user }) {

  try {
    const response = yield call(addLimit, user)

    yield put(addLimitSuccess(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}


function* onDeleteLimit({ payload: user }) {
  try {
    const response = yield call(deleteLimit, user)
    console.log("klsmdfklds", response, user)
    yield put(deleteLimitSuccess(response, user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}

function* onGetLimit(item) {
  try {
    const response = yield call(getLimit, item.users)

    console.log("response111", response)

    yield put(getLimitSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* onGetProduct(item) {
  try {
    const response = yield call(getProducts1, item.users)
    yield put(getProductSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}


function* onGetSliders(item) {
  try {
    const response = yield call(getSliders, item.users)
    yield put(getSlidersSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}


function* onGetBlogs(item) {
  try {
    const response = yield call(getBlogs, item.users)
    yield put(getBlogsSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* onGetSchool(item) {
  try {
    if(item != null){
      const response = yield call(getSchool, item.payload)
      yield put(getSchoolSuccess(response))
    }else{
      const response = yield call(getSchool, item.payload)
      yield put(getSchoolSuccess(response))
    }
   
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* onAddSchool({ payload: user }) {

  try {
    const response = yield call(addSchool, user)

    yield put(addSchoolSuccess(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}

function* onUpdateSchool({ payload: user }) {
  try {
    const response = yield call(updateSchool, user)
    yield put(updateSchoolSuccess(response))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}

function* onDeleteSchool({ payload: user }) {
  try {
    const response = yield call(deleteSchool, user)
    console.log("klsmdfklds", response, user)
    yield put(deleteSchoolSuccess(response, user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}


function* onGetUsersStudent(item) {
  try {

      const response = yield call(getUsersStudent, item.payload)
      console.log("response1231",response,item.payload)
      yield put(getStudentSuccess(response))
  
   
  } catch (error) {
    console.log("şmsdşf",error)
    yield put(getUsersFail(error))
  }
}

function* onGetTeacherSchool(item) {
  try {

      const response = yield call(getUsersTeacher, item.payload)
      console.log("response1231",response)
      yield put(getTeacherSchoolSuccess(response))
  
   
  } catch (error) {
    console.log("şmsdşf",error)
    yield put(getUsersFail(error))
  }
}


function* onGetUsersWeb(item) {
  try {
  
      const response = yield call(getUsersWeb, item.payload)
      console.log("şlmsdşlfd",response)
      yield put(getUsersWebSuccess(response))
  
   
  } catch (error) {
    console.log("şkmsdşfsdf",error)
    yield put(getUsersFail(error))
  }
}


function* onGetUsers(item) {
  try {
  
      const response = yield call(getUsers, item.payload)
      yield put(getUsersSuccess(response))
  
   
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* onGetDynamicFormExtraExtra(item) {
  try {
    const response = yield call(getDynamicFormExtraExtraMega, item.users)
    yield put(getDynamicFormExtraExtraSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* onGetDynamicFormExtra(item) {
  try {
    const response = yield call(getDynamicFormExtraMega, item.users)
    yield put(getDynamicFormExtraSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* onGetClasses() {
  try {
    const response = yield call(getClassesMega)
    yield put(getClassesSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* onGetClassAttendance() {
  try {
    const response = yield call(getClassAttendance)
    yield put(getClassAttendanceSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}


function* onGetTeacherWorkshopMega() {
  try {
    const response = yield call(getTeacherWorkshop)
    yield put(getTeacherWorkshopSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* onGetSubProject(item) {
  try {
    const response = yield call(getSubProject(item.news))
    yield put(getSubProjectSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* onUpdateLessonsTime({ payload: user }) {
  try {
    const response = yield call(updateLessonsTime, user)
    yield put(updateLessonsTimeSuccess(response))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}

function* onAddBrand({ payload: user }) {

  try {
    const response = yield call(addBrand, user)

    yield put(addBrandSuccess(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}


function* onAddEducation({ payload: user }) {

  try {
    const response = yield call(addEducation, user)

    yield put(addEducationSuccess(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}





function* onAddLessonsTime({ payload: user }) {

  try {
    const response = yield call(addLessonsTime, user)

    yield put(addSuccessLessonsTime(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}

function* onAddReferances({ payload: user }) {

  try {
    const response = yield call(addReferances, user)

    yield put(addSuccessReferances(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}

function* onGetReferances() {
  try {
    const response = yield call(getReferances)
    yield put(getReferancesSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* fetchGallery() {
  try {
    const response = yield call(getGallery)
    yield put(getGallerySuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}


function* fetchAuthority() {
  try {
    const response = yield call(getAuthority)
    yield put(getAuthSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* fetchContact() {
  try {
    const response = yield call(getContact)
    yield put(getMegaSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* fetchAbout() {
  try {
    const response = yield call(getAbout)
    yield put(getAboutSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* onUpdateAuth({ payload: user }) {
  try {
    const response = yield call(updateAuth, user)
    yield put(getAuthUpdateSuccess(response))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}


function* onGetPdr(item) {
  try {
    const response = yield call(getPdr, item.users)

    console.log("response111", response)

    yield put(getPdrSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}


function* onAddCounter({ payload: user }) {

  try {
    const response = yield call(addCounter, user)

    console.log("vv123123",response.value)
    yield put(addCounterSuccess(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}


function* onUpdateCounter({ payload: user }) {
  try {
    const response = yield call(updateCounter, user)
    yield put(updateCounterSuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}

function* onGetCounter(item) {
  try {
    const response = yield call(getCounter, item.users)
    yield put(getCounterSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* onDeleteCounter({ payload: user }) {
  try {
    const response = yield call(deleteCounter, user)
    console.log("klsmdfklds", response, user)
    yield put(deleteCounterSuccess(response, user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}


function* onAddPdr({ payload: user }) {

  try {
    const response = yield call(addPdr, user)

    yield put(addPdrSuccess(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}


function* onUpdateBook({ payload: user }) {
  try {
    const response = yield call(updateBook, user)
    yield put(updateBookSuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}


function* onGetSalesPoint(item) {
  try {
    const response = yield call(getSalesPoint, item.users)
    yield put(getSalesPointSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* onDeleteSalesPoint({ payload: user }) {
  try {
    const response = yield call(deleteSalesPoint, user)
    console.log("klsmdfklds", response, user)
    yield put(deleteSalesPointSuccess(response, user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}


function* onAddSalesPoint({ payload: user }) {

  try {
    const response = yield call(addSalesPoint, user)

    yield put(addSalesPointSuccess(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}


function* onUpdateSalesPoint({ payload: user }) {
  try {
    const response = yield call(updateSalesPoint, user)
    yield put(updateSalesPointSuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}

function* onGetBook(item) {
  try {
    const response = yield call(getBook, item.users)
    yield put(getBookSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}



function* onDeletePostCoach({ payload: user }) {
  try {
    const response = yield call(deletePostCoach, user)
    console.log("klsmdfklds", response, user)
    yield put(deletePostCoachSuccess(response, user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}


function* onAddPostCoach({ payload: user }) {

  try {
    const response = yield call(addPostCoach, user)

    yield put(addNewPostCoachSuccess(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}


function* onUpdatePostCoach({ payload: user }) {
  try {
    const response = yield call(updatePostCoach, user)
    yield put(updatePostCoachSuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}

function* onGetPostCoach(item) {
  try {
    const response = yield call(getPostCoach, item.users)

    console.log("lmsdfşl",response)
    yield put(getPostCoachSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* onAddBook({ payload: user }) {

  try {
    const response = yield call(addBook, user)

    yield put(addBookSuccess(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}

function* onDeleteBook({ payload: user }) {
  try {
    const response = yield call(deleteBook, user)
    console.log("klsmdfklds", response, user)
    yield put(deleteBookSuccess(response, user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}


function* onDeletePdr({ payload: user }) {
  try {
    const response = yield call(deletePdr, user)
    console.log("klsmdfklds", response, user)
    yield put(deletePdrSuccess(response, user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}

function* onUpdatePdr({ payload: user }) {
  try {
    const response = yield call(updatePdr, user)
    yield put(updatePdrSuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}

function* onUpdateReferances({ payload: user }) {
  try {
    const response = yield call(updateReferances, user)
    yield put(updateSuccessReferances(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}

function* onUpdateProject({ payload: user }) {
  try {
    const response = yield call(updateProject, user)
    yield put(updateSuccessProject(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}


function* onUpdateBlog({ payload: user }) {
  try {
    const response = yield call(updateBlog, user)
    yield put(updateBlogSuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}



function* onUpdateProductCategory({ payload: user }) {
  try {
    const response = yield call(updateProductCategory, user)
    console.log("jlnsdfl", response)
    yield put(updateProductCategorySuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}




function* onUpdateTeacher({ payload: user }) {
  try {
    const response = yield call(updateTeacher, user)
    yield put(updateTeacherSuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}

function* onUpdateWorkshopSession({ payload: user }) {
  try {
    const response = yield call(updateWorkshopSession, user)
    yield put(updateWorkShopSessionSuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}


function* onUpdateSlider({ payload: user }) {
  try {
    const response = yield call(updateSlider, user)
    yield put(updateSliderSuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}

function* onUpdateSubdomain({ payload: user }) {
  try {
    const response = yield call(updateSubdomain, user)
    yield put(updateSubdomainSuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}


function* fetchMega() {
  try {
    const response = yield call(getMegaMega)
    yield put(getMegaSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* fetchNews() {
  try {
    const response = yield call(getNews)
    yield put(getNewsSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}


function* fetchProjects() {
  try {
    const response = yield call(getProjectsNew)
    yield put(getProjectSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}


function* fetchSlider() {
  try {
    const response = yield call(getSlider)
    yield put(getSliderSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}


function* fetcDynamicFormMega() {
  try {
    const response = yield call(getDynamicFormMega)
    yield put(getDynamicSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* fetcEventMega() {
  try {
    const response = yield call(getEventMega)
    yield put(getUsersSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* fetcTeacherMega() {
  try {
    const response = yield call(getTeacherMega)
    yield put(getTeacherSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* fetchWorkshopSessionMega() {
  try {
    const response = yield call(getWorkshopSessionMega)
    yield put(getApplicationSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}
function* fetchCourseSchedulesMega() {
  try {
    const response = yield call(getCourseSchedulesMega)
    yield put(getWorkshopSessionSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* fetchWorkshop2() {
  try {
    const response = yield call(getWorkshop2)
    yield put(getWorkshop2Success(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* fetchPopup() {
  try {
    const response = yield call(getPopup)
    console.log("response123", response)
    yield put(getPopupSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* fetchWorkshop() {
  try {
    const response = yield call(getWorkShop)
    yield put(getWorkshopSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* fetchUsers() {
  try {
    const response = yield call(getUsers)
    yield put(getUsersSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* fetchUserProfile() {
  try {
    const response = yield call(getUserProfile)
    yield put(getUserProfileSuccess(response))
  } catch (error) {
    yield put(getUserProfileFail(error))
  }
}

function* onUpdateCourseSchedules({ payload: user }) {
  try {
    const response = yield call(updateCourseSchedules, user)
    yield put(updateSuccessCourseSchedules(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}

function* onUpdateNews({ payload: user }) {
  try {
    const response = yield call(updateNews, user)
    yield put(updateNewsSuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}

function* onUpdateGalllery({ payload: user }) {
  try {
    const response = yield call(updateGallery, user)
    yield put(updateGallerySuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}

function* onUpdateWorkshop2({ payload: user }) {
  try {
    const response = yield call(updateWorkShop2, user)
    yield put(updateWorkShop2Success(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}

function* onUpdateMega({ payload: user }) {
  try {
    const response = yield call(updateMega, user)
    yield put(updateMegaSuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}

function* onUpdateUser({ payload: user }) {
  try {
    const response = yield call(updateUser, user)
    yield put(updateUserSuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}

function* onDeleteUser({ payload: user }) {
  try {
    const response = yield call(deleteUser, user)
    console.log("klsmdfklds", response, user)
    yield put(deleteUserSuccess(response, user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}




function* onAddDynamicForm({ payload: user }) {

  try {
    const response = yield call(addDynamicForm, user)

    yield put(addDynamicFormSuccess(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}

function* onAdddNewWorkshop2({ payload: user }) {

  try {
    const response = yield call(addWorkshop2, user)

    yield put(addUserSuccess(response))
  } catch (error) {

    yield put(addUserFail(error))
  }
}

function* onAddWorkshopSession({ payload: user }) {

  try {
    const response = yield call(addAppSetting, user)

    yield put(addUserSuccess(response))
  } catch (error) {

    yield put(addUserFail(error))
  }
}

function* onAddWorkshopSubdomain({ payload: user }) {

  try {
    const response = yield call(addNewSubdomain, user)

    yield put(addUserSuccess(response))
  } catch (error) {

    yield put(addUserFail(error))
  }
}


function* onAddNewUser({ payload: user }) {

  try {
    const response = yield call(addNewUser, user)

    yield put(addUserSuccess(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}

function* onAddCourse({ payload: user }) {

  try {
    const response = yield call(addCourseMega, user)

    console.log("response123213", response)
    yield put(addUserSuccess(response))
  } catch (error) {

    yield put(addUserFail(error))
  }
}
function* onAddNewMega({ payload: user }) {

  try {
    const response = yield call(addMega, user)

    console.log("respocccc13", response.value[0])
    yield put(addMegaSuccess(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}

function* onAddNews({ payload: user }) {

  try {
    const response = yield call(addNews, user)

    console.log("response123213", response)
    yield put(addUserSuccess(response))
  } catch (error) {

    yield put(addUserFail(error))
  }
}

function* onAddProject({ payload: user }) {

  try {
    const response = yield call(addProject, user)

    console.log("response123213", response)
    yield put(addUserSuccess(response))
  } catch (error) {

    yield put(addUserFail(error))
  }
}


function* onAddNewPost({ payload: user }) {

  try {
    const response = yield call(addNewPost, user)

    yield put(addNewPostSuccess(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}
function* onAddSlider({ payload: user }) {

  try {
    const response = yield call(addSlider, user)

    console.log("response123213", response)
    yield put(addSliderSuccess(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}

function* onAddPopup({ payload: user }) {

  try {
    const response = yield call(addPopup, user)

    console.log("response123213", response)
    yield put(addPopupSuccess(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}

function* onDeleteMega({ payload: user }) {
  try {
    const response = yield call(deleteMega, user)
    yield put(deleteMegaSuccess(response, user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}

function* onDeleteProductCategory({ payload: user }) {
  try {
    const response = yield call(deleteProductCategory, user)
    yield put(deleteProductCategorySuccess(response, user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}

function* onDeleteNews({ payload: user }) {
  try {
    const response = yield call(deleteNews, user)
    yield put(deleteNewsSuccess(response, user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}

function* onDeleteSlider({ payload: user }) {
  try {
    const response = yield call(deleteSlider, user)
    console.log("user123", user)
    yield put(deleteSliderSuccess(response, user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}

function* onDeletePopup({ payload: user }) {
  try {
    const response = yield call(deletePopup, user)
    console.log("user123", user)
    yield put(deletePopupSuccess(response, user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}

function* onDeleteReferance({ payload: user }) {
  try {
    const response = yield call(deleteReferances, user)
    console.log("user123", user)
    yield put(deleteReferancesSuccess(response, user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}


function* onAddProductSubCategory({ payload: user }) {

  try {
    const response = yield call(addProductSubCategory, user)

    yield put(addProductSubCategorySuccess(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}

function* onAddGallery({ payload: user }) {

  try {
    const response = yield call(addGallerry, user)

    yield put(addGallerySuccess(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}


function* onAddProductCategory({ payload: user }) {

  try {
    const response = yield call(addProductCategory, user)

    yield put(addProductCategorySuccess(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}




function* onAddUser({ payload: user }) {

  try {
    const response = yield call(addUser, user)

    yield put(addUserSuccess(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}

function* onAddBlog({ payload: user }) {

  try {
    const response = yield call(addBlog, user)

    yield put(addBlogSuccess(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}


function* onDeleteGallery({ payload: user }) {
  try {
    const response = yield call(deleteGallery, user)
    yield put(deleteGallerySuccess(response, user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}

function* onAddMultipleGallery({ payload: user }) {

  try {
    const response = yield call(addMultipleGalleryForm, user)

    //yield put(addGallerySuccess(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}


function* onDeleteCourseSchedules({ payload: user }) {
  try {
    const response = yield call(deleteCourseSchedules, user)
    yield put(deleteCourseSchedulesSuccess(response, user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}

function* onDeleteDynamicFormExtra({ payload: user }) {
  try {
    const response = yield call(deleteDynamicFormExtra, user)
    yield put(deleteDynamicExtraFormSuccess(response, user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}

function* onDeleteDynamicForm({ payload: user }) {
  try {
    const response = yield call(deleteDynamicForm, user)
    yield put(deleteDynamicFormSuccess(response, user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}

function* onDeleteDynamicFormExtraExtra({ payload: user }) {
  try {
    const response = yield call(deleteDynamicFormExtraExtra, user)
    yield put(deleteDynamicExtraExtraFormSuccess(response, user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}

function* onAddSongCategory({ payload: user }) {
  
  try {
    const response = yield call(addSongCategory, user)

    yield put(addSongCategorySuccess(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}


function* onDeleteSongCategory({ payload: user }) {
  try {
    const response = yield call(deleteSongCategory, user)
    yield put(deleteSongCategorySuccess(response,user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}

function* onUpdatePost({ payload: user }) {
  try {
    const response = yield call(updatePost, user)
    yield put(updatePostSuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}


function* onDeleteMotivation({ payload: user }) {
  try {
    const response = yield call(deleteMotivation, user)
    yield put(deleteMotivationSuccess(response,user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}



function* onUpdateMotivation({ payload: user }) {
  try {
    const response = yield call(updateMotivation, user)
    yield put(updateMotivationSuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}

function* onAddMotivation({ payload: user }) {
  
  try {
    const response = yield call(addMotivation, user)

    yield put(addMotivationSuccess(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}


function* onGetMotivation(item) {
  try {
    const response = yield call(getMotivation, item.users)
    yield put(getMotivationSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* onDeletePost({ payload: user }) {
  try {
    const response = yield call(deletePost, user)
    yield put(deletePostSuccess(response,user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}


function* onGetPost(item) {
  try {
    const response = yield call(getPost, item.users)
    yield put(getPostSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* onGetSongCategory(item) {
  try {
    const response = yield call(getSongCategory, item.users)
    yield put(getSongCategorySuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* onUpdateSongCategory({ payload: user }) {
  try {
    const response = yield call(updateSongCategory, user)
    yield put(updateSongCategorySuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}

function* onUpdateSong({ payload: user }) {
  try {
    const response = yield call(updateSong, user)
    yield put(updateSongSuccess(response.value[0]))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}

function* onDeleteSong({ payload: user }) {
  try {
    const response = yield call(deleteSong, user)
    yield put(deleteSongSuccess(response,user))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}

function* onAddSong({ payload: user }) {
  
  try {
    const response = yield call(addSong, user)

    yield put(addSongSuccess(response.value[0]))
  } catch (error) {

    yield put(addUserFail(error))
  }
}


function* contactsSaga() {
  
  yield takeEvery(GET_USERSWEB, onGetUsersWeb)

  yield takeEvery(GET_STUDENT, onGetUsersStudent)

  yield takeEvery(GET_TEACHERSCHOOL, onGetTeacherSchool)

yield takeEvery(GET_SCHOOL, onGetSchool)
yield takeEvery(ADD_SCHOOL, onAddSchool)
yield takeEvery(UPDATE_SCHOOL, onUpdateSchool)
yield takeEvery(DELETE_SCHOOL, onDeleteSchool)

yield takeEvery(DELETE_PURCH, onDeletePurch)
yield takeEvery(ADD_PURCH, onAddPurch)
yield takeEvery(UPDATE_PURCH, onUpdatePurch)
yield takeEvery(GET_PURCH, onGetPurch)


    yield takeEvery(DELETE_FEEDBACKCATEGORY, onDeleteFeedBackCategory)
    yield takeEvery(ADD_FEEDBACKCATEGORY, onAddFeedbackCategory)
    yield takeEvery(UPDATE_FEEDBACKCATEGORY, onUpdateFeedbackCategory)
    yield takeEvery(GET_FEEDBACKCATEGORY, onGetFeedBackCategory)


    yield takeEvery(GET_FEEDBACK, onGetFeedBack)
    yield takeEvery(DELETE_FEEDBACK, onDeleteFeedBack)
    yield takeEvery(ADD_FEEDBACK, onAddFeedBack)
    yield takeEvery(UPDATE_FEEDBACK, onUpdateFeedBack)

    
  yield takeEvery(UPDATE_COACH, onUpdatePostCoach)
  yield takeEvery(ADD_COACH, onAddPostCoach)
  yield takeEvery(GET_COACH, onGetPostCoach)
  yield takeEvery(DELETE_COACH, onDeletePostCoach)

yield takeEvery(UPDATE_SALES_POINT, onUpdateSalesPoint)
yield takeEvery(ADD_SALES_POINT, onAddSalesPoint)
yield takeEvery(GET_SALES_POINT, onGetSalesPoint)
yield takeEvery(DELETE_SALES_POINT, onDeleteSalesPoint)

yield takeEvery(UPDATE_COUNTER, onUpdateCounter)
yield takeEvery(ADD_COUNTER, onAddCounter)
yield takeEvery(GET_COUNTER, onGetCounter)
yield takeEvery(DELETE_COUNTER, onDeleteCounter)

  yield takeEvery(UPDATE_BOOK, onUpdateBook)
  yield takeEvery(ADD_BOOK, onAddBook)
  yield takeEvery(GET_BOOK, onGetBook)
  yield takeEvery(DELETE_BOOK, onDeleteBook)
  
yield takeEvery(UPDATE_MOTIVATION, onUpdateMotivation)
yield takeEvery(DELETE_MOTIVATION, onDeleteMotivation)
yield takeEvery(ADD_MOTIVATION, onAddMotivation)
yield takeEvery(GET_MOTIVATION, onGetMotivation)

  yield takeEvery(UPDATE_POST, onUpdatePost)

  yield takeEvery(DELETE_POST, onDeletePost)

  yield takeEvery(GET_POST, onGetPost)

  yield takeEvery(ADD_SONG, onAddSong)

  yield takeEvery(DELETE_SONG, onDeleteSong)

  yield takeEvery(DELETE_SONG_CATEGORY, onDeleteSongCategory)
  yield takeEvery(ADD_SONG_CATEGORY, onAddSongCategory)
  yield takeEvery(GET_SONG_CATEGORY, onGetSongCategory)
  yield takeEvery(UPDATE_SONG_CATEGORY, onUpdateSongCategory)

  yield takeEvery(GET_SONG, onGetSong)
  yield takeEvery(UPDATE_SONG, onUpdateSong)

  yield takeEvery(DELETE_TEACHER, onDeleteTeacher)

  yield takeEvery(ADD_NEWPOST, onAddNewPost)

yield takeEvery(GET_PDR, onGetPdr)
yield takeEvery(ADD_PDR, onAddPdr)
yield takeEvery(DELETE_PDR, onDeletePdr)
yield takeEvery(UPDATE_PDR, onUpdatePdr)


  yield takeEvery(ADD_LIMIT, onAddLimit)

  yield takeEvery(DELETE_LIMIT, onDeleteLimit)

  yield takeEvery(UPDATE_LIMIT, onUpdateLimit)

  yield takeEvery(GET_LIMIT, onGetLimit)

  yield takeEvery(DELETE_SUBSUBCATEGORY, onDeleteProductSubSubCategory)

  yield takeEvery(DELETE_PRODUCTSUBCATEGORY, onDeleteProductSubCategory)

  yield takeEvery(UPDATE_POPUP, onUpdatePopup)

  yield takeEvery(UPDATE_PRODUCTSUBCATEGORY, onUpdateProductSubCategory)

  yield takeEvery(ADD_SUBCATEGORY, onAddProductSubCategory)

  yield takeEvery(ADD_BRAND, onAddBrand)

  yield takeEvery(UPDATE_SUBSUBCATEGORY, onUpdateSubSubProductCategory)

  yield takeEvery(ADD_SUBSUBCATEGORY, onAddProductSubSubCategory)

  yield takeEvery(GET_PRODUCTSUBSUBCATEGORY, onGetProductSubSubCategory)

  yield takeEvery(UPDATE_BRAND, onUpdateBrand)


  yield takeEvery(GET_BRAND, onGetBrand)

  yield takeEvery(GET_PRODUCTSUBCATEGORY, onGetProductSubCategory)


  yield takeEvery(GET_RESERVATION, onGetReservation)
  yield takeEvery(DELETE_RESERVATION, onDeleteReservation)

  yield takeEvery(ADD_RESERVATION, onAddReservation)

  yield takeEvery(UPDATE_RESERVATION, onUpdateReservation)




  yield takeEvery(GET_ADVERT, onGetAdvert)
  yield takeEvery(DELETE_ADVERT, onDeleteAdvert)

  yield takeEvery(ADD_ADVERT, onAddAdvert)

  yield takeEvery(UPDATE_ADVERT, onUpdateAdvert)




  yield takeEvery(GET_AVAILABLE, onGetAvailable)
  yield takeEvery(DELETE_AVAILABLE, onDeleteAvailable)

  yield takeEvery(ADD_AVAILABLE, onAddAvailable)

  yield takeEvery(UPDATE_AVAILABLE, onUpdateAvailable)


  yield takeEvery(DELETE_SUBEDUCATION, onDeleteSubEducation)


  yield takeEvery(DELETE_PRODUCT, onDeleteProduct)
  yield takeEvery(ADD_PRODUCT, onAddProduct)

  yield takeEvery(UPDATE_PRODUCT, onUpdateProduct)

  

  yield takeEvery(DELETE_BLOG, onDeleteBlog)

  yield takeEvery(DELETE_SSS, onDeleteSss)
  yield takeEvery(ADD_SSS, onAddSSS)
  yield takeEvery(GET_SSS, onGetSSS)
  yield takeEvery(UPDATE_SSS, onUpdateSSS)


  yield takeEvery(DELETE_OFFER, onDeleteOffer)
  yield takeEvery(ADD_OFFER, onAddOffer)
  yield takeEvery(GET_OFFER, onGetOffer)
  yield takeEvery(UPDATE_OFFER, onUpdateOffer)

  yield takeEvery(ADD_SUBEDUCATION, onAddSubEducation)

  yield takeEvery(UPDATE_SUBEDUCATION, onUpdateSubEducation)

  yield takeEvery(ADD_EDUCATION, onAddEducation)

  yield takeEvery(UPDATE_EDUCATION, onUpdateEducation)

  yield takeEvery(GET_SUBEDUCATION, onGetEducationSubItem)

  yield takeEvery(GET_EDUCATION, onGetEducation)
  yield takeEvery(GET_PRODUCTCATEGORY, ongetProductCategory)

  yield takeEvery(GET_PRODUCT, onGetProduct)

  yield takeEvery(GET_SLİDERS, onGetSliders)
  yield takeEvery(GET_BLOGS, onGetBlogs)

  yield takeEvery(GET_USERS, onGetUsers)

  yield takeEvery(DELETE_DYNAMICFORMEXTRAEXTRA, onDeleteDynamicFormExtraExtra)

  yield takeEvery(DELETE_DYNAMICFORM, onDeleteDynamicForm)

  yield takeEvery(DELETE_DYNAMICFORMEXTRA, onDeleteDynamicFormExtra)

  yield takeEvery(DELETE_COURSESCHEDULES, onDeleteCourseSchedules)

  yield takeEvery(ADD_GALLERYMULTIPLE, onAddMultipleGallery)

  yield takeEvery(DELETE_GALLERY, onDeleteGallery)

  yield takeEvery(ADD_GALLERY, onAddGallery)


  yield takeEvery(ADD_PRODUCTCATEGORY, onAddProductCategory)

  yield takeEvery(ADD_BLOG, onAddBlog)

  yield takeEvery(DELETE_REFERANCES, onDeleteReferance)

  yield takeEvery(DELETE_POPUP, onDeletePopup)

  yield takeEvery(DELETE_SLIDER, onDeleteSlider)

  yield takeEvery(DELETE_NEWS, onDeleteNews)

  yield takeEvery(DELETE_MEGA, onDeleteMega)
  yield takeEvery(DELETE_PRODUCTCATEGORY, onDeleteProductCategory)

  yield takeEvery(GET_DYNAMICFORM_EXTRA_EXTRA, onGetDynamicFormExtraExtra)

  yield takeEvery(GET_DYNAMICFORM_EXTRA, onGetDynamicFormExtra)

  yield takeEvery(ADD_DYNAMIC_FORM, onAddDynamicForm)

  yield takeEvery(UPDATE_DYNAMICFORM, onUpdateDynamicForm)

  yield takeEvery(GET_CLASSES, onGetClasses)

  yield takeEvery(GET_CLASSATTENDANCE, onGetClassAttendance)

  yield takeEvery(UPDATE_LESSONS_TIME, onUpdateLessonsTime)

  yield takeEvery(ADD_LESSONS_TIME, onAddLessonsTime)

  yield takeEvery(GET_TEACHER_WORKSHOP, onGetTeacherWorkshopMega)

  yield takeEvery(GET_SUBPROJECT, onGetSubProject)

  yield takeEvery(UPDATE_REFERANCES, onUpdateReferances)

  yield takeEvery(ADD_REFERANCES, onAddReferances)

  yield takeEvery(GET_REFERANCES, onGetReferances)

  yield takeEvery(ADD_WORKSHOP2, onAdddNewWorkshop2)

  yield takeEvery(ADD_APPSETTING, onAddWorkshopSession)

  yield takeEvery(ADD_WORKSHOPSUBDOMAIN, onAddWorkshopSubdomain)

  yield takeEvery(UPDATE_GALLERY, onUpdateGalllery)

  yield takeEvery(GET_GALLERY, fetchGallery)

  yield takeEvery(UPDATE_WORKSHOP2, onUpdateWorkshop2)

  yield takeEvery(GET_WORKSHOP2, fetchWorkshop2)

  yield takeEvery(UPDATE_MEGA, onUpdateMega)

  yield takeEvery(UPDATE_PROJECT, onUpdateProject)

  yield takeEvery(UPDATE_BLOG, onUpdateBlog)
  yield takeEvery(UPDATE_PRODUCTCATEGORY, onUpdateProductCategory)

  yield takeEvery(UPDATE_SLIDER, onUpdateSlider)

  yield takeEvery(UPDATE_COURSESCHEDULES, onUpdateCourseSchedules)

  yield takeEvery(UPDATE_AUTH, onUpdateAuth)

  yield takeEvery(GET_AUTHORITY, fetchAuthority)

  yield takeEvery(UPDATE_CONTACT, onUpdateContact)

  yield takeEvery(UPDATE_ABOUT, onUpdateAbout)

  yield takeEvery(GET_CONTACT, fetchContact)
  yield takeEvery(GET_ABOUT, fetchAbout)

  yield takeEvery(UPDATE_TEACHER, onUpdateTeacher)

  yield takeEvery(UPDATE_WORKSHOPSESSION, onUpdateWorkshopSession)

  yield takeEvery(UPDATE_SUBDOMAIN, onUpdateSubdomain)




  yield takeEvery(ADD_COURSE, onAddCourse)

  yield takeEvery(UPDATE_NEWS, onUpdateNews)

  yield takeEvery(ADD_MEGA, onAddNewMega)

  yield takeEvery(ADD_NEWS, onAddNews)

  yield takeEvery(ADD_PROJECT, onAddProject)

  yield takeEvery(ADD_SLIDER, onAddSlider)

  yield takeEvery(ADD_POPUP, onAddPopup)

  yield takeEvery(GET_MEGA, fetchMega)

  yield takeEvery(GET_NEWS, fetchNews)

  yield takeEvery(GET_PROJECTSNEW, fetchProjects)

  yield takeEvery(GET_SLIDER, fetchSlider)

  yield takeEvery(GET_DYNAMICFORM, fetcDynamicFormMega)

  yield takeEvery(GET_EVENT, fetcEventMega)

  yield takeEvery(GET_TeacherMega, fetcTeacherMega)

  yield takeEvery(GET_CourseSchedulesMega, fetchCourseSchedulesMega)

  yield takeEvery(GET_WorkshopSessionMega, fetchWorkshopSessionMega)

  yield takeEvery(GET_WORKSHOP, fetchWorkshop)

  yield takeEvery(GET_POPUP, fetchPopup)

  yield takeEvery(GET_USERS, fetchUsers)
  yield takeEvery(GET_USER_PROFILE, fetchUserProfile)
  yield takeEvery(ADD_NEW_USER, onAddNewUser)
  yield takeEvery(ADD_NEW_USER, onAddUser)

  
  yield takeEvery(UPDATE_USER, onUpdateUser)
  yield takeEvery(DELETE_USER, onDeleteUser)
}

export default contactsSaga;
